var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"alpha"},[_c('div',{staticClass:"the-footer"},[_c('div',{staticClass:"livecoinwatch-widget-5",attrs:{"lcw-base":"USD","lcw-color-tx":"#999999","lcw-marquee-1":"coins","lcw-marquee-2":"movers","lcw-marquee-items":"10"}}),_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"logo-container"},[_vm._m(0),_c('div',{staticClass:"footer-link-container2"},[_c('h3',{staticClass:"footer-title"},[_vm._v("Links")]),_c('a',{staticClass:"footer-link",on:{"click":_vm.onPostClick}},[_vm._v("Home")]),_c('a',{staticClass:"footer-link",on:{"click":_vm.onPostClick2}},[_vm._v("Login")]),_c('a',{staticClass:"footer-link",on:{"click":_vm.onPostClick3}},[_vm._v("Register")]),_c('a',{staticClass:"footer-link",on:{"click":_vm.onPostClick4}},[_vm._v("F.A.Q")]),_c('a',{staticClass:"footer-link",on:{"click":_vm.onPostClick5}},[_vm._v("Contacts")]),_c('a',{staticClass:"footer-link",attrs:{"href":"https://find-and-update.company-information.service.gov.uk/company/10477019"}},[_vm._v("Certifications")])]),_vm._m(1)])]),_c('hr'),_vm._m(2),_vm._m(3),_vm._m(4),_c('hr'),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-link-container"},[_c('h3',{staticClass:"footer-title"},[_vm._v("About Us")]),_c('p',{staticClass:"footer-link width"},[_vm._v(" Established on November 14, 2016, Income And Assets Limited has its headquarters at 53 Bristol Road Bristol Road, Chippenham, England, SN15 1NT with the official registration under company number 10477019. Our company takes pride in being a trusted and innovative digital asset, stocks, forex trading platform tailored for a global clientele, leveraging the cutting-edge technology of Bittrex. We are committed to delivering a secure and reliable platform that offers advanced solutions in various financial domains, including Financial Administration, Risk Management, Investment Services, and Return Provisioning. Our mission is to empower our international customers with state-of-the-art tools and expertise for successful trading and financial management. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stay-connected"},[_c('h3',{staticClass:"footer-title"},[_vm._v("Stay connected")]),_c('div',{staticClass:"socials-container"},[_c('div',{staticClass:"social-logo"},[_c('div',{staticClass:"last-div"},[_c('i',{staticClass:"bx bxs-map"}),_c('p',{staticClass:"last-div-para contain"},[_vm._v("53 Bristol Road Bristol Road, Chippenham, England, SN15 1NT")])])]),_c('div',{staticClass:"social-logo"},[_c('div',{staticClass:"last-div"},[_c('i',{staticClass:"bx bx-mail-send",staticStyle:{"color":"#ffffff"}}),_c('p',{staticClass:"last-div-para"},[_c('a',{attrs:{"href":"mailto:support@incomeandassetslimited.com"}},[_vm._v("support@incomeandassetslimited.com")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"copyright"},[_c('p',[_vm._v("Income And Assets Limited LLC is part of Income And Assets Limited Group of companies with broker entities that are authorised and regulated across multiple jurisdictions. Income And Assets Limited LLC has it's business address at the United Kingdom.")]),_c('br'),_c('p',[_vm._v("The objects of the Company are all subject matters not forbidden by International Business Companies (Amendment and Consolidation) Act, Chapter 149 of the Revised Laws of Saint Vincent and Grenadines, 2009, in particular but not exclusively all commercial, financial, lending, borrowing, trading, service activities and the participation in other enterprises as well as to provide brokerage, training and managed account services in currencies, commodities, indexes, CFDs and leveraged financial instruments.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-part"},[_c('a',{attrs:{"href":"https://coinmarketcap.com/"}},[_c('img',{attrs:{"src":"https://infiniteprotrades.com/assets/home/wp-content/uploads/2020/03/bitcoin-1.svg"}})]),_c('a',{attrs:{"href":""}},[_c('img',{attrs:{"src":"https://infiniteprotrades.com/assets/home/wp-content/uploads/2020/03/mastercard-1.svg"}})]),_c('a',{attrs:{"href":""}},[_c('img',{attrs:{"src":require("@/assets/bankwire.svg")}})]),_c('a',{attrs:{"href":"https://crypto.com/"}},[_c('img',{attrs:{"src":"https://infiniteprotrades.com/assets/home/wp-content/uploads/2020/03/america-express.svg"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"copyright"},[_c('p',[_vm._v("IAL Markets is, depending on the context, a reference to IAL Markets Germany GmbH, IAL Markets UK plc or IAL Spreadbet plc. IAL Markets Germany GmbH is a company licensed and regulated by the Bundesanstalt für Finanzdienstleistungsaufsicht (BaFin) under registration number 154814. IAL Markets UK Plc and IAL Spreadbet plc are registered in the Register of Companies of the Financial Conduct Authority under registration numbers 173730 and 170627.")]),_c('br'),_c('p',[_vm._v("Telephone calls and online chat conversations may be recorded and monitored. Apple, iPad, and iPhone are trademarks of Apple Inc., registered in the U.K. and other countries. App Store is a service mark of Apple Inc. Android is a trademark of Google Inc. This website uses cookies to obtain information about your general internet usage. Removal of cookies may affect the operation of certain parts of this website. Learn about cookies and how to remove them. Portions of this page are reproduced from work created and shared by Google and used according to terms described in the Creative Commons 3.0 Attribution License.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"copyright"},[_c('p',[_vm._v("All rights reserved. Copyright © 2016 - 2024")])])
}]

export { render, staticRenderFns }