<template>
  <div class="alpha">
    <navigation-view/>
    <!--    <a href="https://wa.me/15102569690?text="  target="_blank" class="whatsapp_float">-->
    <!--      <i class='bx bxl-whatsapp'></i>-->
    <!--    </a>-->

    <!--        <router-view v-slot="{ Component }">-->
    <!--          <transition name="route" mode="out-in">-->
    <!--            <component :is="Component"></component>-->
    <!--          </transition>-->
    <!--        </router-view>-->

    <transition name="slide-fade" mode="out-in">
      <router-view/>
    </transition>
  </div>
</template>


<script>
import NavigationView from "@/components/BaseComponents/NavigationView.vue";
import Toastify from 'toastify-js'
import 'toastify-js/src/toastify.css'

export default {
  components: {NavigationView},
  data() {
    return {
      messages: [
        "🇦🇷 Sophia made a deposit of $845,000",
        "🇦🇺 Lucas made a deposit of $1,000,000",
        "🇧🇷 Mia made a deposit of $63,000",
        "🇨🇦 Noah made a deposit of $250,000",
        "🇨🇱 Liam made a deposit of $1,000",
        "🇨🇴 Charlotte made a deposit of $755,000",
        "🇨🇷 Harper made a deposit of $234,000",
        "🇨🇺 Oliver made a deposit of $44,000",
        "🇩🇰 Amelia made a deposit of $321,000",
        "🇩🇴 Evelyn made a deposit of $589,000",
        "🇪🇨 William made a deposit of $60,000",
        "🇪🇸 Ava made a deposit of $490,000",
        "🇫🇮 James made a deposit of $158,000",
        "🇫🇷 Abigail made a deposit of $117,000",
        "🇬🇧 Isabella made a deposit of $650,000",
        "🇬🇷 Benjamin made a deposit of $800,000",
        "🇬🇹 Jacob made a deposit of $1,000",
        "🇭🇺 Michael made a deposit of $985,000",
        "🇮🇸 Ethan made a deposit of $15,000",
        "🇮🇹 Alexander made a withdrawal of $300,000",
        "🇯🇵 Emily made a deposit of $840,000",
        "🇰🇷 Matthew made a deposit of $72,000",
        "🇲🇽 Aiden made a deposit of $240,000",
        "🇳🇱 Madison made a deposit of $965,000",
        "🇳🇴 Logan made a deposit of $104,000",
        "🇵🇦 Lily made a deposit of $22,000",
        "🇵🇪 Sofia made a deposit of $58,000",
        "🇵🇭 Elijah made a deposit of $378,000",
        "🇵🇱 Mason made a deposit of $54,000",
        "🇵🇷 Camila made a deposit of $320,000",
        "🇵🇹 Scarlett made a deposit of $100,000",
        "🇵🇾 Grace made a deposit of $890,000",
        "🇷🇴 Riley made a deposit of $103,000",
        "🇷🇸 Zoey made a deposit of $129,000",
        "🇷🇺 Nora made a deposit of $800,000",
        "🇸🇪 Hannah made a deposit of $95,000",
        "🇸🇬 Lily made a deposit of $335,000",
        "🇸🇮 Sofia made a deposit of $460,000",
        "🇸🇰 Avery made a deposit of $9,000",
        "🇸🇻 Chloe made a deposit of $278,000",
        "🇸🇽 Benjamin made a deposit of $67,000",
        "🇸🇾 Elijah made a deposit of $750,000",
        "🇹🇭 Lucas made a deposit of $410,000",
        "🇹🇷 Emma made a deposit of $243,000",
        "🇺🇦 Sofia made a deposit of $125,000",
        "🇺🇸 Jackson made a deposit of $315,000",
        "🇺🇾 Aria made a deposit of $550,000",
        "🇻🇪 Daniel made a deposit of $2,000",
        "🇻🇳 David made a deposit of $135,000",
        "🇾🇪 Carter made a withdrawal of $200,000",
        "🇦🇹 Jayden made a deposit of $775,000",
        "🇧🇪 Hunter made a deposit of $965,000",
        "🇧🇬 Levi made a deposit of $85,000",
        "🇧🇭 Sebastian made a deposit of $490,000",
        "🇧🇳 Julian made a deposit of $57,000",
        "🇧🇴 Mateo made a deposit of $15,000",
        "🇧🇸 Jack made a deposit of $400,000",
        "🇧🇾 Owen made a deposit of $39,000",
        "🇨🇦 Victoria made a deposit of $775,000",
        "🇨🇭 Gabriel made a deposit of $33,000",
        "🇨🇮 Lucy made a deposit of $93,000",
        "🇨🇱 Samuel made a deposit of $7,000",
        "🇨🇲 Ryan made a deposit of $650,000",
        "🇨🇳 Nora made a deposit of $412,000",
        "🇨🇴 Anna made a deposit of $190,000",
        "🇨🇷 Caroline made a deposit of $500,000",
        "🇨🇾 Wyatt made a deposit of $850,000",
        "🇨🇿 Addison made a deposit of $3,000",
        "🇩🇪 Aaron made a deposit of $225,000",
        "🇩🇰 Sadie made a deposit of $175,000",
        "🇩🇴 Brooklyn made a deposit of $370,000",
        "🇩🇿 Gabriel made a deposit of $500,000",
        "🇪🇨 Lillian made a deposit of $350,000",
        "🇪🇪 Hannah made a deposit of $415,000",
        "🇪🇬 Isaac made a deposit of $230,000",
        "🇪🇸 Layla made a deposit of $880,000",
        "🇪🇺 Ella made a deposit of $985,000",
        "🇫🇮 Aubrey made a deposit of $700,000",
        "🇫🇯 Joshua made a deposit of $15,000",
        "🇫🇴 Henry made a deposit of $32,000",
        "🇫🇷 Leo made a deposit of $400,000",
        "🇬🇦 Zoe made a deposit of $990,000",
        "🇬🇧 Stella made a deposit of $880,000",
        "🇬🇪 Allison made a deposit of $80,000",
        "🇬🇱 Jonathan made a deposit of $440,000",
        "🇬🇳 Parker made a deposit of $220,000",
        "🇬🇵 Savannah made a deposit of $540,000",
        "🇬🇷 Eliana made a deposit of $95,000",
        "🇬🇹 Claire made a deposit of $640,000",
        "🇬🇾 Skylar made a deposit of $775,000",
        "🇭🇰 Easton made a deposit of $810,000",
        "🇭🇳 Maya made a deposit of $500,000",
        "🇭🇷 Lincoln made a deposit of $370,000",
        "🇭🇹 Julian made a deposit of $660,000",
        "🇭🇺 Camila made a deposit of $995,000",
        "🇮🇩 Naomi made a deposit of $590,000",
        "🇮🇪 Samantha made a deposit of $35,000",
        "🇮🇱 Caroline made a deposit of $10,000",
        "🇮🇲 Nora made a deposit of $750,000",
        "🇮🇳 Zoe made a deposit of $210,000",
        "🇮🇶 Christian made a deposit of $785,000",
        "🇮🇷 Everly made a deposit of $985,000",
        "🇮🇸 Gianna made a deposit of $490,000",
        "🇮🇹 Kennedy made a deposit of $960,000",
        "🇯🇪 Jaxon made a deposit of $130,000",
        "🇯🇲 Landon made a deposit of $775,000",
        "🇯🇴 Easton made a deposit of $850,000",
        "🇯🇵 Kayden made a deposit of $350,000",
        "🇰🇬 Ariana made a deposit of $700,000",
        "🇰🇭 Allison made a deposit of $485,000",
        "🇰🇮 Lincoln made a deposit of $50,000",
        "🇰🇲 Robert made a deposit of $225,000",
        "🇰🇳 Hazel made a deposit of $710,000",
        "🇰🇵 Nathan made a deposit of $630,000",
        "🇰🇷 Autumn made a deposit of $385,000",
        "🇰🇼 Nevaeh made a deposit of $640,000",
        "🇰🇾 Oliver made a deposit of $350,000",
        "🇰🇿 Charlotte made a deposit of $995,000",
        "🇱🇦 Aiden made a deposit of $750,000",
        "🇱🇧 Sophia made a deposit of $470,000",
        "🇱🇨 Mia made a deposit of $10,000",
        "🇱🇮 Harper made a deposit of $225,000",
        "🇱🇰 Amelia made a deposit of $45,000",
        "🇱🇷 Evelyn made a deposit of $760,000",
        "🇱🇸 Abigail made a deposit of $950,000",
        "🇱🇹 Isabella made a deposit of $210,000",
        "🇱🇺 Benjamin made a deposit of $750,000",
        "🇱🇻 Jacob made a deposit of $410,000",
        "🇱🇾 Michael made a deposit of $650,000",
        "🇲🇨 Ethan made a deposit of $310,000",
        "🇲🇩 Alexander made a deposit of $775,000",
        "🇲🇪 Emily made a deposit of $880,000",
        "🇲🇫 Matthew made a deposit of $360,000",
        "🇲🇬 Aiden made a withdrawal of $540,000",
        "🇲🇭 Madison made a deposit of $540,000",
        "🇲🇰 Logan made a deposit of $700,000",
        "🇲🇱 Lily made a deposit of $300,000",
        "🇲🇲 Sofia made a deposit of $150,000",
        "🇲🇳 Elijah made a deposit of $430,000",
        "🇲🇴 Mason made a deposit of $600,000",
        "🇲🇵 Camila made a deposit of $10,000",
        "🇲🇶 Scarlett made a deposit of $900,000",
        "🇲🇷 Grace made a deposit of $200,000",
        "🇲🇸 Riley made a deposit of $960,000",
        "🇲🇹 Zoey made a deposit of $350,000",
        "🇲🇺 Nora made a deposit of $10,000",
        "🇲🇻 Hannah made a deposit of $800,000",
        "🇲🇼 Lily made a deposit of $450,000",
        "🇲🇽 Sofia made a deposit of $900,000",
        "🇲🇾 Avery made a deposit of $60,000",
        "🇲🇿 Chloe made a deposit of $900,000",
        "🇳🇦 Benjamin made a deposit of $330,000",
        "🇳🇨 Elijah made a deposit of $1,000,000",
        "🇳🇪 Lucas made a deposit of $235,000",
        "🇳🇫 Emma made a deposit of $340,000",
        "🇳🇬 Ava made a deposit of $760,000",
        "🇳🇮 James made a deposit of $210,000",
        "🇳🇱 Abigail made a deposit of $350,000",
        "🇳🇴 Isabella made a deposit of $950,000",
        "🇳🇵 Benjamin made a deposit of $210,000",
        "🇳🇷 Jacob made a deposit of $235,000",
        "🇳🇺 Michael made a deposit of $660,000",
        "🇳🇿 Ethan made a deposit of $775,000",
        "🇴🇲 Alexander made a deposit of $485,000",
        "🇵🇦 Emily made a deposit of $250,000",
        "🇵🇪 Matthew made a deposit of $410,000",
        "🇵🇫 Aiden made a deposit of $950,000",
        "🇵🇬 Madison made a deposit of $60,000",
        "🇵🇭 Logan made a deposit of $360,000",
        "🇵🇰 Lily made a deposit of $440,000",
        "🇵🇱 Sofia made a deposit of $640,000",
        "🇵🇲 Elijah made a deposit of $80,000",
        "🇵🇳 Mason made a deposit of $640,000",
        "🇵🇷 Camila made a deposit of $485,000",
        "🇵🇸 Scarlett made a deposit of $310,000",
        "🇵🇹 Grace made a deposit of $90,000",
        "🇵🇼 Riley made a deposit of $660,000",
        "🇵🇾 Zoey made a deposit of $50,000",
        "🇶🇦 Nora made a deposit of $985,000",
        "🇷🇪 Hannah made a deposit of $410,000",
        "🇷🇴 Lily made a deposit of $75,000",
        "🇷🇺 Sofia made a deposit of $900,000",
        "🇷🇼 Avery made a deposit of $400,000",
        "🇸🇦 Chloe made a deposit of $150,000",
        "🇸🇧 Benjamin made a deposit of $640,000",
        "🇸🇨 Elijah made a deposit of $440,000",
        "🇸🇩 Lucas made a deposit of $640,000",
        "🇸🇪 Emma made a deposit of $800,000",
        "🇸🇬 Ava made a deposit of $95,000",
        "🇸🇭 James made a deposit of $150,000",
        "🇸🇮 Abigail made a deposit of $985,000",
        "🇸🇯 Isabella made a deposit of $50,000",
        "🇸🇰 Benjamin made a deposit of $200,000",
        "🇸🇱 Jacob made a deposit of $235,000",
        "🇸🇲 Michael made a deposit of $440,000",
        "🇸🇳 Ethan made a deposit of $250,000",
        "🇸🇴 Alexander made a deposit of $35,000",
        "🇸🇷 Emily made a deposit of $210,000",
        "🇸🇸 Matthew made a deposit of $640,000",
        "🇸🇹 Aiden made a deposit of $950,000",
        "🇸🇻 Madison made a deposit of $540,000",
        "🇸🇾 Logan made a deposit of $640,000",
        "🇸🇿 Lily made a deposit of $540,000",
        "🇹🇦 Sofia made a deposit of $640,000",
        "🇹🇨 Elijah made a deposit of $985,000",
        "🇹🇩 Mason made a deposit of $640,000",
        "🇹🇫 Camila made a deposit of $95,000",
        "🇹🇬 Scarlett made a deposit of $900,000",
        "🇹🇭 Grace made a deposit of $250,000",
        "🇹🇯 Riley made a deposit of $640,000",
        "🇹🇰 Zoey made a deposit of $95,000",
        "🇹🇱 Nora made a deposit of $900,000",
        "🇹🇲 Hannah made a deposit of $900,000",
        "🇹🇳 Lily made a deposit of $540,000",
        "🇹🇴 Sofia made a deposit of $440,000",
        "🇹🇷 Avery made a deposit of $540,000",
        "🇹🇹 Chloe made a deposit of $250,000",
        "🇹🇻 Benjamin made a deposit of $150,000",
        "🇹🇼 Elijah made a deposit of $210,000",
        "🇹🇿 Lucas made a deposit of $900,000",
        "🇺🇦 Emma made a deposit of $410,000",
        "🇺🇬 Ava made a deposit of $985,000",
        "🇺🇲 James made a deposit of $540,000",
        "🇺🇳 Abigail made a deposit of $250,000",
        "🇺🇸 Isabella made a deposit of $540,000",
        "🇺🇾 Benjamin made a deposit of $95,000",
        "🇻🇦 Jacob made a deposit of $985,000",
        "🇻🇨 Michael made a deposit of $440,000",
        "🇻🇪 Ethan made a deposit of $640,000",
        "🇻🇬 Alexander made a deposit of $50,000",
        "🇻🇮 Emily made a deposit of $985,000",
        "🇻🇳 Matthew made a deposit of $95,000",
        "🇻🇺 Aiden made a deposit of $50,000",
        "🇼🇫 Madison made a withdrawal of $985,000",
        "🇼🇸 Logan made a deposit of $640,000",
        "🇽🇰 Lily made a deposit of $250,000",
        "🇾🇪 Sofia made a deposit of $95,000",
        "🇾🇹 Elijah made a deposit of $640,000",
        "🇿🇦 Mason made a deposit of $50,000",
        "🇿🇲 Camila made a deposit of $440,000",
        "🇿🇼 Scarlett made a deposit of $640,000",
        "🇦🇷 Mia made a deposit of $325,000",
        "🇦🇹 Noah made a deposit of $150,000",
        "🇦🇺 Emma made a deposit of $875,000",
        "🇧🇪 Lucas made a deposit of $230,000",
        "🇧🇷 Olivia made a deposit of $455,000",
        "🇨🇦 Ava made a deposit of $540,000",
        "🇨🇱 Sophia made a deposit of $78,000",
        "🇨🇴 Jacob made a deposit of $112,000",
        "🇩🇪 Mason made a deposit of $920,000",
        "🇫🇷 Isabella made a deposit of $350,000",
        "🇬🇧 William made a deposit of $100,000",
        "🇮🇹 James made a deposit of $400,000",
        "🇯🇵 Amelia made a deposit of $500,000",
        "🇲🇽 Charlotte made a deposit of $600,000",
        "🇳🇱 Alexander made a deposit of $200,000",
        "🇳🇿 Henry made a deposit of $300,000",
        "🇵🇱 Evelyn made a deposit of $490,000",
        "🇸🇪 Abigail made a deposit of $700,000",
        "🇸🇬 Emily made a deposit of $450,000",
        "🇺🇸 Madison made a deposit of $950,000",
        "🇨🇦 Harper made a deposit of $220,000",
        "🇦🇹 Aiden made a deposit of $680,000",
        "🇦🇺 Samuel made a deposit of $570,000",
        "🇧🇪 Joseph made a deposit of $180,000",
        "🇧🇷 Carter made a deposit of $75,000",
        "🇨🇱 Chloe made a deposit of $305,000",
        "🇨🇴 Daniel made a deposit of $820,000",
        "🇩🇪 Grace made a deposit of $250,000",
        "🇫🇷 Matthew made a deposit of $650,000",
        "🇬🇧 Ella made a deposit of $400,000",
        "🇮🇹 Evelyn made a deposit of $910,000",
        "🇯🇵 Harper made a withdrawal of $255,000",
        "🇲🇽 Lillian made a deposit of $180,000",
        "🇳🇱 Nora made a deposit of $990,000",
        "🇳🇿 Scarlett made a deposit of $350,000",
        "🇵🇱 Gabriel made a deposit of $75,000",
        "🇸🇪 Sofia made a deposit of $305,000",
        "🇸🇬 Benjamin made a deposit of $820,000",
        "🇺🇸 Lucas made a deposit of $650,000",
        "🇨🇦 Zoey made a deposit of $400,000",
        "🇦🇹 Riley made a deposit of $510,000",
        "🇦🇺 Layla made a deposit of $980,000",
        "🇧🇪 Camila made a deposit of $180,000",
        "🇧🇷 Julian made a deposit of $390,000",
        "🇨🇱 Levi made a deposit of $95,000",
        "🇨🇴 Allison made a deposit of $305,000",
        "🇩🇪 Anna made a deposit of $820,000",
        "🇫🇷 Ryan made a deposit of $650,000",
        "🇬🇧 Aaliyah made a deposit of $400,000",
        "🇮🇹 Claire made a deposit of $910,000",
        "🇯🇵 Eliana made a deposit of $180,000",
        "🇲🇽 Cooper made a deposit of $990,000",
        "🇳🇱 Xavier made a deposit of $350,000",
        "🇳🇿 Penelope made a deposit of $75,000",
        "🇵🇱 Isaac made a deposit of $305,000",
        "🇸🇪 Violet made a deposit of $820,000",
        "🇸🇬 Lincoln made a deposit of $650,000",
        "🇺🇸 Paisley made a deposit of $400,000",
        "🇨🇦 Eli made a deposit of $510,000",
        "🇦🇹 Landon made a deposit of $180,000",
        "🇦🇺 Brooklyn made a deposit of $990,000",
        "🇧🇪 Weston made a deposit of $350,000",
        "🇧🇷 Nathaniel made a deposit of $75,000",
        "🇨🇱 Bryce made a deposit of $305,000",
        "🇨🇴 Hunter made a deposit of $820,000",
        "🇩🇪 Charles made a deposit of $650,000",
        "🇫🇷 Connor made a deposit of $400,000",
        "🇬🇧 Jeremiah made a deposit of $910,000",
        "🇮🇹 Sarah made a deposit of $180,000",
        "🇯🇵 Adrian made a deposit of $990,000",
        "🇲🇽 Christian made a deposit of $350,000",
        "🇳🇱 Jonathan made a deposit of $75,000",
        "🇳🇿 Christopher made a deposit of $305,000",
        "🇵🇱 Ariel made a deposit of $820,000",
        "🇸🇪 Hazel made a deposit of $650,000",
        "🇸🇬 Sawyer made a deposit of $400,000",
        "🇺🇸 Austin made a deposit of $510,000",
        "🇨🇦 Roman made a deposit of $180,000",
        "🇦🇹 Ian made a deposit of $990,000",
        "🇦🇺 Jose made a deposit of $350,000",
        "🇧🇪 Leonardo made a deposit of $75,000",
        "🇧🇷 Easton made a deposit of $305,000",
        "🇨🇱 Miguel made a deposit of $820,000",
        "🇨🇴 Angel made a deposit of $650,000",
        "🇩🇪 Robert made a deposit of $400,000",
        "🇫🇷 Beau made a deposit of $910,000",
        "🇬🇧 Quinn made a deposit of $180,000",
        "🇮🇹 Piper made a deposit of $990,000",
        "🇯🇵 Max made a deposit of $350,000",
        "🇲🇽 Luna made a deposit of $75,000",
        "🇳🇱 Carson made a deposit of $305,000",
        "🇳🇿 Madeline made a deposit of $820,000",
        "🇵🇱 Sydney made a deposit of $650,000",
        "🇸🇪 Jordan made a deposit of $400,000",
        "🇸🇬 Katherine made a deposit of $910,000",
        "🇺🇸 Brayden made a deposit of $180,000",
        "🇨🇦 Vincent made a deposit of $990,000",
        "🇦🇹 Bella made a deposit of $350,000",
        "🇦🇺 Alex made a deposit of $75,000",
        "🇧🇪 Everett made a deposit of $305,000",
        "🇧🇷 Parker made a deposit of $820,000",
        "🇨🇱 Adam made a deposit of $650,000",
        "🇨🇴 Nolan made a deposit of $400,000",
        "🇩🇪 Luis made a deposit of $910,000",
        "🇫🇷 Juan made a deposit of $180,000",
        "🇬🇧 Charles made a deposit of $990,000",
        "🇮🇹 Grayson made a deposit of $350,000",
        "🇯🇵 King made a deposit of $75,000",
        "🇲🇽 Kyle made a deposit of $305,000",
        "🇳🇱 Benjamin made a deposit of $820,000",
        "🇳🇿 Caleb made a deposit of $650,000",
        "🇵🇱 Miles made a deposit of $400,000",
        "🇸🇪 Micah made a deposit of $910,000",
        "🇸🇬 Wesley made a deposit of $180,000",
        "🇺🇸 Luca made a deposit of $990,000",
        "🇨🇦 Lincoln made a deposit of $350,000",
        "🇦🇹 Jayden made a deposit of $75,000",
        "🇦🇺 Giovanni made a deposit of $305,000",
        "🇧🇪 George made a deposit of $820,000",
        "🇧🇷 Theo made a deposit of $650,000",
        "🇨🇱 Mateo made a deposit of $400,000",
        "🇨🇴 Leonardo made a deposit of $910,000",
        "🇩🇪 Owen made a deposit of $180,000",
        "🇫🇷 Jesse made a deposit of $990,000",
        "🇬🇧 Juan made a deposit of $350,000",
        "🇮🇹 Judah made a deposit of $75,000",
        "🇯🇵 Sawyer made a deposit of $305,000",
        "🇲🇽 Roman made a deposit of $820,000",
        "🇳🇱 Braxton made a deposit of $650,000",
        "🇳🇿 Kingston made a deposit of $400,000",
        "🇵🇱 Spencer made a deposit of $910,000",
        "🇸🇪 Anderson made a deposit of $180,000",
        "🇸🇬 Tucker made a deposit of $990,000",
        "🇺🇸 Waylon made a deposit of $350,000",
        "🇨🇦 Jameson made a deposit of $75,000",
        "🇦🇹 Kevin made a deposit of $305,000",
        "🇦🇺 Bentley made a deposit of $820,000",
        "🇧🇪 Zion made a deposit of $650,000",
        "🇧🇷 Dale made a deposit of $400,000",
        "🇨🇱 Paxton made a deposit of $910,000",
        "🇨🇴 Grant made a deposit of $180,000",
        "🇩🇪 Martin made a deposit of $990,000",
        "🇫🇷 Rafael made a deposit of $350,000",
        "🇬🇧 Max made a deposit of $75,000",
        "🇮🇹 Richard made a deposit of $305,000",
        "🇯🇵 Alejandro made a deposit of $820,000",
        "🇲🇽 Remi made a deposit of $650,000",
        "🇳🇱 Victor made a deposit of $400,000",
        "🇳🇿 Emmanuel made a deposit of $910,000",
        "🇵🇱 Kaden made a deposit of $180,000",
        "🇸🇪 Alonzo made a deposit of $990,000",
        "🇸🇬 Roberto made a deposit of $350,000",
        "🇺🇸 Conrad made a deposit of $75,000",
        "🇨🇦 Finn made a deposit of $305,000",
        "🇦🇹 Francisco made a deposit of $820,000",
        "🇦🇺 Iker made a deposit of $650,000",
        "🇧🇪 Rodrigo made a deposit of $400,000",
        "🇧🇷 Enzo made a deposit of $910,000",
        "🇨🇱 Jaxson made a deposit of $180,000",
        "🇨🇴 Reece made a deposit of $990,000",
        "🇩🇪 Jasper made a deposit of $350,000",
        "🇫🇷 Kameron made a deposit of $75,000",
        "🇬🇧 Kian made a deposit of $305,000",
        "🇮🇹 Samson made a deposit of $820,000",
        "🇯🇵 Cassius made a deposit of $650,000",
        "🇲🇽 Dennis made a deposit of $400,000",
        "🇳🇱 Gibson made a deposit of $910,000",
        "🇳🇿 Chance made a deposit of $180,000",
        "🇵🇱 Warren made a deposit of $990,000",
        "🇸🇪 Landen made a deposit of $350,000",
        "🇸🇬 Daxton made a deposit of $75,000",
        "🇺🇸 Cruz made a deposit of $305,000",
        "🇨🇦 Lawson made a deposit of $820,000",
        "🇦🇹 Mohamed made a deposit of $650,000",
        "🇦🇺 Fisher made a deposit of $400,000",
        "🇧🇪 Jase made a deposit of $910,000",
        "🇧🇷 Bodhi made a deposit of $180,000",
        "🇨🇱 Musa made a deposit of $990,000",
        "🇨🇴 Crew made a deposit of $350,000",
        "🇩🇪 Oakley made a deposit of $75,000",
        "🇫🇷 Tate made a deposit of $305,000",
        "🇬🇧 Ares made a deposit of $820,000",
        "🇮🇹 Brysen made a deposit of $650,000",
        "🇯🇵 Adonis made a deposit of $400,000",
        "🇲🇽 Armando made a deposit of $910,000",
        "🇳🇱 Sam made a deposit of $180,000",
        "🇳🇿 Santino made a deposit of $990,000",
        "🇵🇱 Scott made a deposit of $350,000",
        "🇸🇪 Ryland made a deposit of $75,000",
        "🇸🇬 Trenton made a deposit of $305,000",
        "🇺🇸 Kendall made a deposit of $820,000",
        "🇨🇦 Dominick made a deposit of $650,000",
        "🇦🇹 Eden made a deposit of $400,000",
        "🇦🇺 Kyrie made a deposit of $910,000",
        "🇧🇪 Moises made a deposit of $180,000",
        "🇧🇷 Ricky made a deposit of $990,000",
        "🇨🇱 Jamari made a deposit of $350,000",
        "🇨🇴 Jerry made a deposit of $75,000",
        "🇩🇪 Kace made a deposit of $305,000",
        "🇫🇷 Kole made a deposit of $820,000",
        "🇬🇧 Briar made a deposit of $650,000",
        "🇮🇹 Colten made a deposit of $400,000",
        "🇯🇵 Reign made a deposit of $910,000",
        "🇲🇽 Stetson made a deposit of $180,000",
        "🇳🇱 Azariah made a deposit of $990,000",
        "🇳🇿 Casey made a deposit of $350,000",
        "🇵🇱 Boston made a deposit of $75,000",
        "🇸🇪 Apollo made a deposit of $305,000",
        "🇸🇬 Ulises made a deposit of $820,000",
        "🇺🇸 Lachlan made a deposit of $650,000",
        "🇨🇦 Ledger made a deposit of $400,000",
        "🇦🇹 Emmitt made a deposit of $910,000",
        "🇦🇺 Koa made a deposit of $180,000",
        "🇧🇪 Amos made a deposit of $990,000",
        "🇧🇷 Jaxton made a deposit of $350,000",
        "🇨🇱 Kenji made a deposit of $75,000",
        "🇨🇴 Cairo made a deposit of $305,000",
        "🇩🇪 Ledger made a deposit of $820,000",
        "🇫🇷 Crosby made a deposit of $650,000",
        "🇬🇧 Briggs made a deposit of $400,000",
        "🇮🇹 Titan made a deposit of $910,000",
        "🇯🇵 Karsyn made a deposit of $180,000",
        "🇲🇽 Aziel made a deposit of $990,000",
        "🇳🇱 Omari made a deposit of $350,000",
        "🇳🇿 Watson made a deposit of $75,000",
        "🇵🇱 Kamden made a deposit of $305,000",
        "🇸🇪 Wesson made a deposit of $820,000",
        "🇸🇬 Anakin made a deposit of $650,000",
        "🇺🇸 Forrest made a deposit of $400,000",
        "🇨🇦 Fletcher made a deposit of $910,000",
        "🇦🇹 Duke made a deposit of $180,000",
        "🇦🇺 Kylan made a deposit of $990,000",
        "🇧🇪 Kohen made a deposit of $350,000",
        "🇧🇷 Gustavo made a deposit of $75,000",
        "🇨🇱 Ledger made a deposit of $305,000",
        "🇨🇴 Shiloh made a deposit of $820,000",
        "🇩🇪 Marcel made a deposit of $650,000",
        "🇫🇷 Beckham made a deposit of $400,000",
        "🇬🇧 Thaddeus made a deposit of $910,000",
        "🇮🇹 Lance made a deposit of $180,000",
        "🇯🇵 Zaid made a deposit of $990,000",
        "🇲🇽 Bodie made a deposit of $350,000",
        "🇳🇱 Jax made a deposit of $75,000",
        "🇳🇿 Kairo made a deposit of $305,000",
        "🇵🇱 Zaire made a deposit of $820,000",
        "🇸🇪 Royce made a deposit of $650,000",
        "🇸🇬 Kalel made a deposit of $400,000",
        "🇺🇸 Tru made a deposit of $910,000",
        "🇨🇦 Colter made a deposit of $180,000",
        "🇦🇹 Shep made a deposit of $990,000",
        "🇦🇺 Finnegan made a deposit of $350,000",
        "🇧🇪 Kannon made a deposit of $75,000",
        "🇧🇷 Niklaus made a deposit of $305,000",
        "🇨🇱 Zavier made a deposit of $820,000",
        "🇨🇴 Brixton made a deposit of $650,000",
        "🇩🇪 Kaiser made a deposit of $400,000",
        "🇫🇷 Casen made a deposit of $910,000",
        "🇬🇧 Callan made a deposit of $180,000",
        "🇮🇹 Joziah made a deposit of $990,000",
        "🇯🇵 Keanu made a deposit of $350,000",
        "🇲🇽 Creed made a deposit of $75,000",
        "🇳🇱 Turner made a deposit of $305,000",
        "🇳🇿 Musa made a deposit of $820,000",
        "🇵🇱 Langston made a deposit of $650,000",
        "🇸🇪 Kaleo made a deposit of $400,000",
        "🇸🇬 Yehuda made a deposit of $910,000",
        "🇺🇸 Stellan made a deposit of $180,000",
        "🇨🇦 Zayd made a deposit of $990,000",
        "🇦🇹 Callum made a deposit of $350,000",
        "🇦🇺 Zain made a deposit of $75,000",
        "🇧🇪 Aarav made a deposit of $305,000",
        "🇧🇷 Kylo made a deposit of $820,000",
        "🇨🇱 Wilson made a deposit of $650,000",
        "🇨🇴 Bridger made a deposit of $400,000",
        "🇩🇪 Lochlan made a deposit of $910,000",
        "🇫🇷 Kye made a deposit of $180,000",
        "🇬🇧 Remy made a deposit of $990,000",
        "🇮🇹 Crosby made a deposit of $350,000",
        "🇯🇵 Jagger made a deposit of $75,000",
        "🇲🇽 Denver made a deposit of $305,000",
        "🇳🇱 Flynn made a deposit of $820,000",
        "🇳🇿 Jair made a deposit of $650,000",
        "🇵🇱 Maxton made a deposit of $400,000",
        "🇸🇪 Baylor made a deposit of $910,000",
        "🇸🇬 Baylor made a deposit of $180,000",
        "🇺🇸 Wilder made a deposit of $990,000",
        "🇦🇹 Mia made a deposit of $25,000",
        "🇧🇪 Lucas made a deposit of $78,000",
        "🇨🇦 Aria made a deposit of $250,000",
        "🇩🇰 Noah made a deposit of $30,000",
        "🇪🇸 Ethan made a deposit of $15,000",
        "🇫🇮 Chloe made a deposit of $95,000",
        "🇬🇧 Emily made a deposit of $200,000",
        "🇭🇺 Liam made a deposit of $50,000",
        "🇮🇹 Oliver made a deposit of $130,000",
        "🇯🇵 Sophia made a deposit of $400,000",
        "🇰🇷 Harper made a deposit of $600,000",
        "🇱🇻 Benjamin made a deposit of $80,000",
        "🇲🇽 Charlotte made a deposit of $350,000",
        "🇳🇴 Amelia made a deposit of $120,000",
        "🇴🇲 Jack made a deposit of $85,000",
        "🇵🇱 Abigail made a deposit of $90,000",
        "🇶🇦 Alexander made a deposit of $65,000",
        "🇷🇴 Mia made a deposit of $160,000",
        "🇸🇪 Madison made a deposit of $250,000",
        "🇹🇷 Mason made a deposit of $400,000",
        "🇺🇸 Logan made a deposit of $700,000",
        "🇻🇪 Ella made a deposit of $550,000",
        "🇼🇸 Michael made a deposit of $120,000",
        "🇾🇪 Daniel made a deposit of $110,000",
        "🇿🇲 Henry made a withdrawal of $75,000",
        "🇦🇷 Jayden made a deposit of $350,000",
        "🇧🇦 Scarlett made a deposit of $220,000",
        "🇨🇱 Leo made a deposit of $85,000",
        "🇩🇪 Isabella made a deposit of $950,000",
        "🇪🇸 Julia made a deposit of $180,000",
        "🇫🇷 David made a deposit of $370,000",
        "🇬🇧 Mateo made a deposit of $90,000",
        "🇭🇷 Lily made a deposit of $400,000",
        "🇮🇸 Ryan made a deposit of $65,000",
        "🇯🇴 Zoe made a deposit of $920,000",
        "🇰🇵 Grace made a deposit of $140,000",
        "🇱🇺 Jackson made a deposit of $230,000",
        "🇲🇾 Wyatt made a deposit of $110,000",
        "🇳🇱 Victoria made a deposit of $300,000",
        "🇴🇲 Christian made a deposit of $150,000",
        "🇵🇪 Lillian made a deposit of $170,000",
        "🇶🇦 Nora made a deposit of $190,000",
        "🇷🇸 Stella made a deposit of $250,000",
        "🇸🇪 Hazel made a deposit of $450,000",
        "🇹🇭 Penelope made a deposit of $320,000",
        "🇺🇦 Levi made a deposit of $60,000",
        "🇻🇦 Allison made a deposit of $550,000",
        "🇼🇸 Jonathan made a deposit of $210,000",
        "🇾🇪 Gabriel made a deposit of $950,000",
        "🇿🇦 Samantha made a deposit of $110,000",
        "🇦🇹 Audrey made a deposit of $220,000",
        "🇧🇪 Bella made a deposit of $800,000",
        "🇨🇦 Claire made a deposit of $85,000",
        "🇩🇪 Aiden made a deposit of $170,000",
        "🇪🇪 Anna made a deposit of $490,000",
        "🇫🇮 Isaac made a deposit of $310,000",
        "🇬🇷 Ariana made a deposit of $350,000",
        "🇭🇷 Jaxon made a deposit of $50,000",
        "🇮🇹 Caroline made a deposit of $290,000",
        "🇯🇵 Eli made a deposit of $160,000",
        "🇰🇷 Eleanor made a deposit of $120,000",
        "🇱🇺 Natalie made a deposit of $440,000",
        "🇲🇾 Luna made a deposit of $300,000",
        "🇳🇱 Savannah made a deposit of $450,000",
        "🇴🇲 David made a deposit of $700,000",
        "🇵🇱 Brody made a deposit of $110,000",
        "🇶🇦 Lucy made a deposit of $180,000",
        "🇷🇸 Muhammad made a deposit of $390,000",
        "🇸🇪 Layla made a deposit of $85,000",
        "🇹🇷 Theodore made a deposit of $140,000",
        "🇺🇦 Eliana made a deposit of $250,000",
        "🇻🇪 Nicholas made a deposit of $520,000",
        "🇼🇸 Mila made a deposit of $115,000",
        "🇾🇪 Maya made a deposit of $950,000",
        "🇿🇲 Evan made a deposit of $210,000",
        "🇦🇷 Amelia made a deposit of $125,000",
        "🇧🇦 Oliver made a deposit of $200,000",
        "🇨🇱 Charlotte made a deposit of $185,000",
        "🇩🇪 James made a deposit of $400,000",
        "🇪🇪 Harper made a deposit of $90,000",
        "🇫🇮 Evelyn made a deposit of $250,000",
        "🇬🇷 Abigail made a deposit of $650,000",
        "🇭🇷 William made a deposit of $230,000",
        "🇮🇸 Alexander made a deposit of $470,000",
        "🇯🇴 Mia made a deposit of $60,000",
        "🇰🇵 Michael made a deposit of $900,000",
        "🇱🇺 Benjamin made a deposit of $350,000",
        "🇲🇾 Emma made a deposit of $120,000",
        "🇳🇱 Mason made a deposit of $85,000",
        "🇴🇲 Logan made a deposit of $300,000",
        "🇵🇪 Ella made a deposit of $750,000",
        "🇶🇦 Oliver made a deposit of $600,000",
        "🇷🇸 Sophia made a deposit of $185,000",
        "🇸🇪 Harper made a deposit of $300,000",
        "🇹🇷 Benjamin made a deposit of $490,000",
        "🇺🇦 Charlotte made a deposit of $85,000",
        "🇻🇪 Amelia made a deposit of $750,000",
        "🇼🇸 Jack made a deposit of $220,000",
        "🇾🇪 Abigail made a deposit of $450,000",
        "🇿🇲 Alexander made a deposit of $600,000",
        "🇦🇷 Mia made a deposit of $250,000",
        "🇧🇦 Lucas made a deposit of $120,000",
        "🇨🇱 Aria made a deposit of $95,000",
        "🇩🇪 Noah made a deposit of $300,000",
        "🇪🇸 Ethan made a deposit of $15,000",
        "🇫🇮 Chloe made a deposit of $650,000",
        "🇬🇧 Emily made a deposit of $120,000",
        "🇭🇺 Liam made a deposit of $290,000",
        "🇮🇹 Oliver made a deposit of $130,000",
        "🇯🇵 Sophia made a deposit of $400,000",
        "🇰🇷 Harper made a withdrawal of $600,000",
        "🇱🇻 Benjamin made a deposit of $300,000",
        "🇲🇽 Charlotte made a deposit of $85,000",
        "🇳🇴 Amelia made a deposit of $750,000",
        "🇴🇲 Jack made a deposit of $300,000",
        "🇵🇱 Abigail made a deposit of $90,000",
        "🇶🇦 Alexander made a deposit of $65,000",
        "🇷🇴 Mia made a deposit of $250,000",
        "🇸🇪 Madison made a deposit of $120,000",
        "🇹🇷 Mason made a deposit of $400,000",
        "🇺🇸 Logan made a deposit of $85,000",
        "🇻🇪 Ella made a deposit of $550,000",
        "🇼🇸 Michael made a deposit of $600,000",
        "🇾🇪 Daniel made a deposit of $290,000",
        "🇿🇲 Henry made a deposit of $350,000",
        "🇦🇷 Jayden made a deposit of $220,000",
        "🇧🇦 Scarlett made a deposit of $850,000",
        "🇨🇱 Leo made a deposit of $185,000",
        "🇩🇪 Isabella made a deposit of $950,000",
        "🇪🇸 Julia made a deposit of $180,000",
        "🇫🇷 David made a deposit of $370,000",
        "🇬🇧 Mateo made a deposit of $90,000",
        "🇭🇷 Lily made a deposit of $400,000",
        "🇮🇸 Ryan made a deposit of $65,000",
        "🇯🇴 Zoe made a deposit of $920,000",
        "🇰🇵 Grace made a deposit of $140,000",
        "🇱🇺 Jackson made a deposit of $230,000",
        "🇲🇾 Wyatt made a deposit of $110,000",
        "🇳🇱 Victoria made a deposit of $300,000",
        "🇴🇲 Christian made a deposit of $150,000",
        "🇵🇪 Lillian made a deposit of $170,000",
        "🇶🇦 Nora made a deposit of $190,000",
        "🇷🇸 Stella made a deposit of $250,000",
        "🇸🇪 Hazel made a deposit of $450,000",
        "🇹🇭 Penelope made a deposit of $320,000",
        "🇺🇦 Levi made a deposit of $60,000",
        "🇻🇦 Allison made a deposit of $550,000",
        "🇼🇸 Jonathan made a deposit of $210,000",
        "🇾🇪 Gabriel made a deposit of $950,000",
        "🇿🇦 Samantha made a deposit of $110,000",
        "🇦🇹 Audrey made a deposit of $220,000",
        "🇧🇪 Bella made a deposit of $800,000",
        "🇨🇦 Claire made a deposit of $85,000",
        "🇩🇪 Aiden made a deposit of $170,000",
        "🇪🇪 Anna made a deposit of $490,000",
        "🇫🇮 Isaac made a deposit of $310,000",
        "🇬🇷 Ariana made a deposit of $350,000",
        "🇭🇷 Jaxon made a deposit of $50,000",
        "🇮🇹 Caroline made a deposit of $290,000",
        "🇯🇵 Eli made a deposit of $160,000",
        "🇰🇷 Eleanor made a deposit of $120,000",
        "🇱🇺 Natalie made a deposit of $440,000",
        "🇲🇾 Luna made a deposit of $300,000",
        "🇳🇱 Savannah made a deposit of $450,000",
        "🇴🇲 David made a deposit of $700,000",
        "🇵🇱 Brody made a deposit of $110,000",
        "🇶🇦 Lucy made a deposit of $180,000",
        "🇷🇸 Muhammad made a deposit of $390,000",
        "🇸🇪 Layla made a deposit of $85,000",
        "🇹🇷 Theodore made a deposit of $140,000",
        "🇺🇦 Eliana made a deposit of $250,000",
        "🇻🇪 Nicholas made a deposit of $520,000",
        "🇼🇸 Mila made a deposit of $115,000",
        "🇾🇪 Maya made a deposit of $950,000",
        "🇿🇲 Evan made a deposit of $210,000",
        "🇦🇷 Amelia made a deposit of $125,000",
        "🇧🇦 Oliver made a deposit of $200,000",
        "🇨🇱 Charlotte made a deposit of $185,000",
        "🇩🇪 James made a deposit of $400,000",
        "🇪🇪 Harper made a deposit of $90,000",
        "🇫🇮 Evelyn made a deposit of $250,000",
        "🇬🇷 Abigail made a deposit of $650,000",
        "🇭🇷 William made a deposit of $230,000",
        "🇮🇸 Alexander made a deposit of $470,000",
        "🇯🇴 Mia made a deposit of $60,000",
        "🇰🇵 Michael made a deposit of $900,000",
        "🇱🇺 Benjamin made a deposit of $350,000",
        "🇲🇾 Emma made a deposit of $120,000",
        "🇳🇱 Mason made a deposit of $85,000",
        "🇴🇲 Logan made a deposit of $300,000",
        "🇵🇪 Ella made a deposit of $750,000",
        "🇶🇦 Oliver made a deposit of $600,000",
        "🇷🇸 Sophia made a deposit of $185,000",
        "🇸🇪 Harper made a deposit of $300,000",
        "🇹🇷 Benjamin made a deposit of $490,000",
        "🇺🇦 Charlotte made a deposit of $85,000",
        "🇻🇪 Amelia made a deposit of $750,000",
        "🇼🇸 Jack made a deposit of $220,000",
        "🇾🇪 Abigail made a deposit of $450,000",
        "🇿🇲 Alexander made a deposit of $600,000",
        "🇦🇷 Mia made a deposit of $250,000",
        "🇧🇦 Lucas made a deposit of $120,000",
        "🇨🇱 Aria made a deposit of $95,000",
        "🇩🇪 Noah made a deposit of $300,000",
        "🇪🇸 Ethan made a deposit of $15,000",
        "🇫🇮 Chloe made a deposit of $650,000",
        "🇬🇧 Emily made a deposit of $120,000",
        "🇭🇺 Liam made a deposit of $290,000",
        "🇮🇹 Oliver made a deposit of $130,000",
        "🇯🇵 Sophia made a deposit of $400,000",
        "🇰🇷 Harper made a deposit of $300,000",
        "🇱🇻 Benjamin made a deposit of $300,000",
        "🇲🇽 Charlotte made a deposit of $85,000",
        "🇳🇴 Amelia made a deposit of $750,000",
        "🇴🇲 Jack made a deposit of $300,000",
        "🇵🇱 Abigail made a deposit of $90,000",
        "🇶🇦 Alexander made a deposit of $65,000",
        "🇷🇴 Mia made a deposit of $250,000",
        "🇸🇪 Madison made a deposit of $120,000",
        "🇹🇷 Mason made a deposit of $400,000",
        "🇺🇸 Logan made a deposit of $85,000",
        "🇻🇪 Ella made a deposit of $550,000",
        "🇼🇸 Michael made a deposit of $600,000",
        "🇾🇪 Daniel made a deposit of $290,000",
        "🇿🇲 Henry made a deposit of $350,000",
        "🇦🇷 Jayden made a deposit of $220,000",
        "🇧🇦 Scarlett made a deposit of $850,000",
        "🇨🇱 Leo made a deposit of $185,000",
        "🇩🇪 Isabella made a deposit of $950,000",
        "🇪🇸 Julia made a deposit of $180,000",
        "🇫🇷 David made a deposit of $370,000",
        "🇬🇧 Mateo made a deposit of $90,000",
        "🇭🇷 Lily made a deposit of $400,000",
        "🇮🇸 Ryan made a deposit of $65,000",
        "🇯🇴 Zoe made a deposit of $920,000",
        "🇰🇵 Grace made a deposit of $140,000",
        "🇱🇺 Jackson made a deposit of $230,000",
        "🇲🇾 Wyatt made a deposit of $110,000",
        "🇳🇱 Victoria made a deposit of $300,000",
        "🇴🇲 Christian made a deposit of $150,000",
        "🇵🇪 Lillian made a deposit of $170,000",
        "🇶🇦 Nora made a deposit of $190,000",
        "🇷🇸 Stella made a deposit of $250,000",
        "🇸🇪 Hazel made a deposit of $450,000",
        "🇹🇭 Penelope made a deposit of $320,000",
        "🇺🇦 Levi made a deposit of $60,000",
        "🇻🇦 Allison made a deposit of $550,000",
        "🇼🇸 Jonathan made a deposit of $210,000",
        "🇾🇪 Gabriel made a deposit of $950,000",
        "🇿🇦 Samantha made a deposit of $110,000",
        "🇦🇹 Audrey made a deposit of $220,000",
        "🇧🇪 Bella made a deposit of $800,000",
        "🇨🇦 Claire made a withdrawal of $85,000",
        "🇩🇪 Aiden made a deposit of $170,000",
        "🇪🇪 Anna made a deposit of $490,000",
        "🇫🇮 Isaac made a deposit of $310,000",
        "🇬🇷 Ariana made a deposit of $350,000",
        "🇭🇷 Jaxon made a deposit of $50,000",
        "🇮🇹 Caroline made a deposit of $290,000",
        "🇯🇵 Eli made a deposit of $160,000",
        "🇰🇷 Eleanor made a deposit of $120,000",
        "🇱🇺 Natalie made a deposit of $440,000",
        "🇲🇾 Luna made a deposit of $300,000",
        "🇳🇱 Savannah made a deposit of $450,000",
        "🇴🇲 David made a deposit of $700,000",
        "🇵🇱 Brody made a deposit of $110,000",
        "🇶🇦 Lucy made a deposit of $180,000",
        "🇷🇸 Muhammad made a deposit of $390,000",
        "🇸🇪 Layla made a deposit of $85,000",
        "🇹🇷 Theodore made a deposit of $140,000",
        "🇺🇦 Eliana made a deposit of $250,000",
        "🇻🇪 Nicholas made a deposit of $520,000",
        "🇼🇸 Mila made a deposit of $115,000",
        "🇾🇪 Maya made a deposit of $950,000",
        "🇿🇲 Evan made a deposit of $210,000",
        "🇮🇳 Benjamin made a deposit of $8,800",
        "🇯🇵 Lucas made a deposit of $9,900",
        "🇨🇳 Mia made a deposit of $11,000",
        "🇦🇺 Charlotte made a deposit of $12,100",
        "🇿🇦 Amelia made a deposit of $13,200",
        "🇬🇧 Elijah made a deposit of $14,300",
        "🇨🇦 Harper made a deposit of $15,400",
        "🇺🇸 Evelyn made a deposit of $16,500",
        "🇫🇷 Abigail made a deposit of $17,600",
        "🇲🇽 Elijah made a withdrawal of $18,700",
        "🇧🇷 Logan made a deposit of $19,800",
        "🇮🇳 Oliver made a deposit of $20,900",
        "🇯🇵 Isabella made a deposit of $22,000",
        "🇨🇳 Jacob made a deposit of $23,100",
        "🇦🇺 Sofia made a deposit of $24,200",
        "🇿🇦 Aiden made a deposit of $25,300",
        "🇬🇧 Camila made a deposit of $26,400",
        "🇨🇦 Samuel made a deposit of $27,500",
        "🇺🇸 Victoria made a deposit of $28,600",
        "🇫🇷 Madison made a deposit of $29,700",
        "🇲🇽 Matthew made a deposit of $30,800",
        "🇧🇷 Scarlett made a deposit of $31,900",
        "🇮🇳 Chloe made a deposit of $33,000",
        "🇯🇵 Aria made a deposit of $34,100",
        "🇨🇳 Emily made a deposit of $35,200",
        "🇦🇺 Henry made a deposit of $36,300",
        "🇿🇦 Aubrey made a deposit of $37,400",
        "🇬🇧 Mila made a deposit of $38,500",
        "🇨🇦 Ethan made a deposit of $39,600",
        "🇺🇸 Riley made a deposit of $40,700",
        "🇫🇷 Zoe made a deposit of $41,800",
        "🇲🇽 Layla made a deposit of $42,900",
        "🇧🇷 Lillian made a deposit of $44,000",
        "🇮🇳 Grace made a deposit of $45,100",
        "🇯🇵 Ryan made a deposit of $46,200",
        "🇨🇳 Nora made a deposit of $47,300",
        "🇦🇺 Hannah made a deposit of $48,400",
        "🇿🇦 Lily made a deposit of $49,500",
        "🇬🇧 Owen made a deposit of $50,600",
        "🇨🇦 John made a deposit of $51,700",
        "🇺🇸 Addison made a deposit of $52,800",
        "🇫🇷 Aiden made a deposit of $53,900",
        "🇲🇽 Ella made a deposit of $55,000",
        "🇧🇷 Gabriel made a deposit of $56,100",
        "🇮🇳 Nathan made a deposit of $57,200",
        "🇯🇵 Samuel made a deposit of $58,300",
        "🇨🇳 Mason made a deposit of $59,400",
        "🇦🇺 Penelope made a deposit of $60,500",
        "🇿🇦 Isaac made a deposit of $61,600",
        "🇬🇧 Naomi made a deposit of $62,700",
        "🇨🇦 Carter made a deposit of $63,800",
        "🇺🇸 Christian made a deposit of $64,900",
        "🇫🇷 Audrey made a deposit of $66,000",
        "🇲🇽 Savannah made a deposit of $67,100",
        "🇧🇷 Joshua made a deposit of $68,200",
        "🇮🇳 Wyatt made a deposit of $69,300",
        "🇯🇵 Kennedy made a deposit of $70,400",
        "🇨🇳 Landon made a deposit of $71,500",
        "🇦🇺 Julian made a deposit of $72,600",
        "🇿🇦 Bella made a deposit of $73,700",
        "🇬🇧 Piper made a deposit of $74,800",
        "🇨🇦 Hazel made a deposit of $75,900",
        "🇺🇸 Stella made a deposit of $77,000",
        "🇫🇷 Levi made a deposit of $78,100",
        "🇲🇽 Allison made a deposit of $79,200",
        "🇧🇷 Alexis made a deposit of $80,300",
        "🇮🇳 Nevaeh made a deposit of $81,400",
        "🇯🇵 Clara made a deposit of $82,500",
        "🇨🇳 Elio made a deposit of $83,600",
        "🇦🇺 Luke made a deposit of $84,700",
        "🇿🇦 Eliana made a deposit of $85,800",
        "🇬🇧 Colin made a deposit of $86,900",
        "🇨🇦 Sarah made a deposit of $88,000",
        "🇺🇸 Lincoln made a deposit of $89,100",
        "🇫🇷 Nora made a deposit of $90,200",
        "🇲🇽 Jace made a deposit of $91,300",
        "🇧🇷 Victoria made a deposit of $92,400",
        "🇮🇳 Bryson made a deposit of $93,500",
        "🇯🇵 Lucy made a deposit of $94,600",
        "🇨🇳 Parker made a deposit of $95,700",
        "🇦🇺 Kayden made a deposit of $96,800",
        "🇿🇦 Mateo made a deposit of $97,900",
        "🇬🇧 Josiah made a deposit of $99,000",
        "🇨🇦 Easton made a deposit of $100,100",
        "🇺🇸 Mary made a deposit of $101,200",
        "🇫🇷 Adeline made a deposit of $102,300",
        "🇲🇽 Genesis made a deposit of $103,400",
        "🇧🇷 Ximena made a deposit of $104,500",
        "🇮🇳 Zoe made a deposit of $105,600",
        "🇯🇵 Dylan made a deposit of $106,700",
        "🇨🇳 Ivan made a deposit of $107,800",
        "🇦🇺 Anastasia made a deposit of $108,900",
        "🇿🇦 Wesley made a deposit of $110,000",
        "🇺🇸 John made a deposit of $5,000",
        "🇮🇹 Ivan made a deposit of $1,660",
        "🇪🇸 Evan made a deposit of $750",
        "🇺🇸 Rachel made a deposit of $300",
        "🇮🇳 Quinn made a deposit of $550",
        "🇪🇸 Fiona made a deposit of $2,450",
        "🇨🇳 Ivan made a deposit of $1,500",
        "🇺🇸 Evan made a deposit of $3,450",
        "🇲🇽 Diana made a withdrawal of $280",
        "🇲🇽 Quinn made a deposit of $390",
        "🇿🇦 Bob made a deposit of $1,600",
        "🇦🇪 Oscar made a deposit of $870",
        "🇷🇺 Penny made a deposit of $868",
        "🇩🇪 Evan made a withdrawal of $2,410",
        "🇷🇺 Fiona made a deposit of $485",
        "🇺🇸 Charlie made a deposit of $1,200",
        "🇧🇷 Charlie made a deposit of $458",
        "🇨🇦 Steve made a deposit of $310",
        "🇸🇬 Steve made a withdrawal of $2,170",
        "🇦🇪 Linda made a deposit of $305",
        "🇦🇪 Steve made a deposit of $230",
        "🇦🇪 Evan made a deposit of $1,540",
        "🇰🇷 Ivan made a deposit of $139",
        "🇯🇵 Evan made a deposit of $640",
        "🇸🇬 Linda made a withdrawal of $140",
        "🇺🇸 Rachel made a deposit of $319",
        "🇷🇺 Oscar made a deposit of $106",
        "🇳🇿 Julia made a deposit of $2,064",
        "🇬🇧 Steve made a deposit of $430",
        "🇬🇧 Penny made a deposit of $189",
        "🇫🇷 Mike made a deposit of $920",
        "🇯🇵 Hannah made a deposit of $299",
        "🇨🇳 Ivan made a deposit of $4,530",
        "🇮🇹 Steve made a deposit of $468",
        "🇨🇦 Charlie made a deposit of $1,590",
        "🇦🇪 George made a deposit of $2,160",
        "🇪🇸 George made a deposit of $451",
        "🇨🇦 Fiona made a deposit of $6,470",
        "🇦🇪 Steve made a deposit of $320",
        "🇮🇳 Bob made a deposit of $340",
        "🇷🇺 Ivan made a deposit of $3,000",
        "🇸🇬 Alice made a withdrawal of $327",
        "🇧🇷 Oscar made a deposit of $2,360",
        "🇦🇺 Quinn made a deposit of $1,560",
        "🇸🇦 Steve made a deposit of $470",
        "🇫🇷 Ivan made a deposit of $1,690",
        "🇫🇷 Steve made a withdrawal of $3,340",
        "🇿🇦 Hannah made a deposit of $610",
        "🇲🇽 Linda made a deposit of $4,470",
        "🇧🇷 Nora made a withdrawal of $4,060",
        "🇰🇷 Nora made a deposit of $2,620",
        "🇦🇪 Fiona made a withdrawal of $230",
        "🇦🇪 Hannah made a deposit of $1,040",
        "🇸🇬 Steve made a deposit of $380",
        "🇳🇿 Diana made a deposit of $2,480",
        "🇯🇵 Linda made a deposit of $570",
        "🇰🇷 Linda made a deposit of $540",
        "🇮🇹 Linda made a deposit of $1,250",
        "🇫🇷 George made a deposit of $2,970",
        "🇨🇳 Rachel made a deposit of $1,430",
        "🇨🇦 Bob made a withdrawal of $4,940",
        "🇩🇪 George made a deposit of $1,550",
        "🇩🇿 Alex made a deposit of $50,000",
        "🇮🇹 Maria made a deposit of $166,000",
        "🇪🇸 Lucas made a deposit of $750,000",
        "🇺🇸 Emma made a deposit of $300,000",
        "🇮🇳 Aryan made a withdrawal of $550,000",
        "🇪🇸 Sofia made a deposit of $2,450,000",
        "🇨🇳 Leo made a deposit of $1,000,000",
        "🇲🇽 Diego made a deposit of $280,000",
        "🇲🇽 Isabella made a deposit of $390,000",
        "🇿🇦 Ethan made a deposit of $900,000",
        "🇦🇪 Noah made a deposit of $870,000",
        "🇷🇺 Olivia made a deposit of $868,000",
        "🇩🇪 Daniel made a deposit of $410,000",
        "🇷🇺 Ava made a withdrawal of $485,000",
        "🇺🇸 William made a deposit of $1,000,000",
        "🇧🇷 James made a deposit of $458,000",
        "🇨🇦 Harper made a deposit of $310,000",
        "🇸🇬 Benjamin made a deposit of $170,000",
        "🇦🇪 Amelia made a deposit of $305,000",
        "🇦🇪 Jack made a deposit of $230,000",
        "🇦🇪 Charlotte made a deposit of $540,000",
        "🇰🇷 Henry made a deposit of $139,000",
        "🇯🇵 Evelyn made a deposit of $640,000",
        "🇸🇬 Logan made a deposit of $140,000",
        "🇺🇸 Sophia made a deposit of $319,000",
        "🇷🇺 Jacob made a deposit of $106,000",
        "🇳🇿 Mila made a deposit of $964,000",
        "🇬🇧 Aiden made a deposit of $430,000",
        "🇬🇧 Lily made a deposit of $189,000",
        "🇫🇷 Joseph made a deposit of $920,000",
        "🇯🇵 Zoe made a deposit of $299,000",
        "🇨🇳 Owen made a deposit of $530,000",
        "🇮🇹 Michael made a deposit of $468,000",
        "🇨🇦 Grace made a deposit of $590,000",
        "🇦🇪 Ryan made a deposit of $960,000",
        "🇪🇸 Chloe made a withdrawal of $451,000",
        "🇨🇦 Nora made a deposit of $770,000",
        "🇦🇪 Luke made a deposit of $320,000",
        "🇮🇳 Adam made a deposit of $340,000",
        "🇷🇺 Hannah made a deposit of $1,000,000",
        "🇸🇬 Zoe made a deposit of $327,000",
        "🇧🇷 Eli made a deposit of $860,000",
        "🇦🇺 Ella made a deposit of $560,000",
        "🇸🇦 Scarlett made a deposit of $470,000",
        "🇫🇷 Mason made a deposit of $690,000",
        "🇫🇷 Carter made a deposit of $940,000",
        "🇿🇦 Riley made a deposit of $610,000",
        "🇲🇽 Layla made a deposit of $870,000",
        "🇧🇷 Lillian made a withdrawal of $4,060",
        "🇰🇷 Madison made a deposit of $2,620",
        "🇦🇪 Aria made a withdrawal of $230",
        "🇦🇪 Penelope made a deposit of $1,040",
        "🇸🇬 David made a deposit of $380",
        "🇳🇿 Victoria made a deposit of $2,480",
        "🇯🇵 Samuel made a deposit of $570",
        "🇰🇷 Josephine made a deposit of $540",
        "🇮🇹 Camila made a deposit of $1,250",
        "🇫🇷 Gabriel made a deposit of $2,970",
        "🇨🇳 Julian made a deposit of $1,430",
        "🇨🇦 Lucas made a deposit of $4,940",
        "🇩🇪 Mateo made a deposit of $1,550",
        "🇩🇿 Alex made a deposit of $50,000",
        "🇮🇹 Maria made a deposit of $166,000",
        "🇪🇸 Lucas made a deposit of $750,000",
        "🇺🇸 Emma made a deposit of $300,000",
        "🇮🇳 Aryan made a withdrawal of $550,000",
        "🇪🇸 Sofia made a deposit of $450,000",
        "🇨🇳 Leo made a deposit of $1,000,000",
      ],
      currentMessageIndex: 0, // Track the current message index
    };
  },
  methods: {
    // showToast() {
    //   Toastify({
    //     text: "This is a toast",
    //     duration: 5000,
    //     destination: "https://github.com/apvarun/toastify-js",
    //     newWindow: true,
    //     close: true,
    //     gravity: "bottom", // `top` or `bottom`
    //     position: "left", // `left`, `center` or `right`
    //     stopOnFocus: true, // Prevents dismissing of toast on hover
    //     style: {
    //       background: "linear-gradient(to right, grey, grey)",
    //       width: "400px"
    //     },
    //   }).showToast();
    // },
    // showToast() {
    //   // Define the interval duration in milliseconds (e.g., every 10 seconds)
    //   const intervalDuration = 10000;
    //
    //   setInterval(() => {
    //     Toastify({
    //       text: "This is a toast",
    //       duration: 5000,
    //       destination: "https://github.com/apvarun/toastify-js",
    //       newWindow: true,
    //       close: true,
    //       gravity: "bottom", // `top` or `bottom`
    //       position: "left", // `left`, `center` or `right`
    //       stopOnFocus: true, // Prevents dismissing of toast on hover
    //       style: {
    //         background: "linear-gradient(to right, grey, grey)",
    //         width: "400px"
    //       },
    //     }).showToast();
    //   }, intervalDuration);
    // }

    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        // Generate random index
        const j = Math.floor(Math.random() * (i + 1));
        // Swap elements at indices i and j
        [array[i], array[j]] = [array[j], array[i]];
      }
    },

    showToast() {
      // Shuffle the messages array before starting the interval
      this.shuffleArray(this.messages);

      const intervalDuration = 60000; // Interval duration in milliseconds

      const intervalId = setInterval(() => {
        // Check if all messages have been shown
        if (this.currentMessageIndex >= this.messages.length) {
          clearInterval(intervalId); // Clear the interval
          return; // Exit the function
        }

        // Show the toast with the current message
        Toastify({
          text: this.messages[this.currentMessageIndex],
          duration: 5000,
          close: true,
          gravity: "bottom",
          position: "right",
          stopOnFocus: true,
          style: {
            background: "linear-gradient(to right, #ffffff, #ffffff)",
            // width: "20%",
            color: "#191C1F",
            borderRadius: "4px"
          },
        }).showToast();

        this.currentMessageIndex++; // Move to the next message
      }, intervalDuration);
    },

    applyGlobalInputStyles() {
      const inputs = document.querySelectorAll('input::placeholder, input[type="email"], input[type="text"], input[type="password"], input[type="number"]');
      inputs.forEach(input => {
        input.style.fontSize = '16px';
        input.placeholder.style.fontSize = '16px';
      });
    }


    // showToast() {
    //   const intervalDuration = 20000; // Interval duration in milliseconds
    //
    //   const intervalId = setInterval(() => {
    //     // Check if all messages have been shown
    //     if (this.currentMessageIndex >= this.messages.length) {
    //       clearInterval(intervalId); // Clear the interval
    //       return; // Exit the function
    //     }
    //
    //     // Show the toast with the current message
    //     Toastify({
    //       text: this.messages[this.currentMessageIndex],
    //       duration: 5000,
    //       close: true,
    //       gravity: "bottom",
    //       position: "left",
    //       stopOnFocus: true,
    //       style: {
    //         background: "linear-gradient(to right, #ffffff, #ffffff)",
    //         width: "320px",
    //         padding: "10px",
    //         color: "#232323"
    //       },
    //     }).showToast();
    //
    //     this.currentMessageIndex++; // Move to the next message
    //   }, intervalDuration);
    // }
  },

  mounted() {
    // this.showToast();
    // this.applyGlobalInputStyles();
  }
}

</script>


<style>

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  cursor: pointer;
  counter-reset: Serial;
  font-family: 'BR-Firma-Regular', sans-serif;
  background: hsla(0, 0%, 100%, 1.0);
}
a{
  text-decoration: none;
}
input{
  font-family: 'BR-Firma-Regular', sans-serif;
}
textarea{
  font-family: 'BR-Firma-Regular', sans-serif;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.route-enter-from {
  opacity: 0;
  transform: translateX(100px);
}
.route-enter-active {
  transition: all 1s ease-out;
}
.route-leave-to {
  opacity: 0;
  transform: translateX(-100px);
}
.route-leave-active {
  transition: all 1s ease-in;
}

.mobile-nav-enter-active,
.mobile-nav-leave-active {
  transition: 1s ease all;
}

.mobile-nav-enter-from,
.mobile-nav-leave-to{
  transform: translateX(-250px);
}

.mobile-nav-enter-to{
  transform: translateX(0);
}

.slide-fade-enter {
  opacity: 0;
  transform: translateY(10px);
}

.slide-fade-enter-to {
}

.slide-fade-enter-active {
  transition: all 0.4s ease-in;
}

.slide-fade-leave {
}

.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(10px);
}

.slide-fade-leave-active {
  transition: all 0.4s ease-out;
}


.whatsapp_float {
  position: fixed;
  width: 55px;
  height: 55px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 100;
}

.bxl-whatsapp {
  position: absolute;
  top: 21%;
  right: 22.5%;
}

/* for mobile */
@media screen and (max-width: 767px){
  .whatsapp-icon {
    margin-top: 10px;
  }
  .whatsapp_float {
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 10px;
    font-size: 22px;
  }
}

</style>